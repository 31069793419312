<template>
  <KTCard class="h-100">
    <template v-slot:title>
      <h6 class="mb-0">Incident Alert by Time Period</h6>
    </template>
    <template v-slot:body>
      <div class="text-right">
        <el-date-picker
          v-model="range"
          type="daterange"
          align="left"
          size="mini"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          value-format="timestamp"
          format="dd/MM/yyyy"
        >
        </el-date-picker>
      </div>

      <apexchart :options="options" :series="series" v-if="isReady"></apexchart>
    </template>
  </KTCard>
</template>

<script>
import KTCard from "@/view/content/Card";
import { DashboardService } from "@/core/services/dashboard.service";
import moment from "moment";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "IncidentAlertByTimePeriod",
  components: { KTCard },
  data() {
    return {
      range: [
        moment()
          .startOf("day")
          .valueOf(),
        moment().valueOf()
      ],
      options: {
        chart: {
          type: "bar",
          id: "incident-alert-by-time-period",
          toolbar: {
            autoSelected: "zoom"
          },
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        tooltip: {
          x: {
            format: "dd MMM HH:mm"
          }
        },
        xaxis: {
          type: "datetime",
          labels: {
            hideOverlappingLabels: true,
            rotate: -90,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm"
            }
          }
        },
        yaxis: {
          show: true,
          // labels: {
          //   show: true,
          //   formatter: val => {
          //     return this.$options.filters.number_format(val);
          //   }
          // },
          title: {
            text: "Alert (times)"
          }
        }
      },
      series: [
        // {
        //   name: "series-1",
        //   data: [30, 40, 45, 50, 49, 60, 70, 91],
        // },
      ],
      isReady: false
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    range() {
      this.init();
    }
  },
  methods: {
    async init() {
      let results = await DashboardService.getIncidentAlertByTimePeriod({
        since: this.range[0],
        until: moment(this.range[1])
          .endOf("day")
          .valueOf()
      });
      // this.options.xaxis.categories = results.map(v =>
      //   moment(v.date).format("DD/MM/YYYY HH:00")
      // );
      this.series = [
        {
          name: "Incident Alert",
          data: results.map(v => [v.date, v.count])
        }
      ];
      this.options.xaxis.labels = {
        ...this.options.xaxis.labels,
        style: {
          colors: this.layoutConfig("colors.gray.gray-500"),
          fontSize: "10px",
          fontFamily: this.layoutConfig("font-family")
        }
      };
      this.options.colors = ["#fd8007"];

      this.options = _.cloneDeep(this.options);
      this.isReady = true;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>

<style></style>
