<template>
  <!--begin::Card-->
  <div class="card card-custom gutter-b h-100">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          Active Sources
        </h3>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Chart-->
      <apexchart v-if="isReady" :options="options" :series="series"></apexchart>
      <!--end::Chart-->
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import { DashboardService } from "@/core/services"
import { mapGetters } from "vuex"

export default {
  name: "ActiveSourcesChart",
  components: {},
  data() {
    return {
      sourceName: [],
      sourceNumber: [],
      sourceColor: [],
      isReady: false,
      options: {},
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    // Shared Colors Definition
    this.getActiveSource().then(() => {
      this.generateChart()
    })
  },
  methods: {
    getActiveSource() {
      return DashboardService.getActiveSource().then((source) => {
        source.sources.forEach((e) => {
          this.sourceName.push(e.source)
          this.sourceNumber.push(e.count)
          if (e.source == "instagram") {
            this.sourceColor.push("#c32aa3")
          } else if (e.source == "pantip") {
            this.sourceColor.push("#1F1575")
          } else if (e.source == "facebook") {
            this.sourceColor.push("#3b5998")
          } else if (e.source == "youtube") {
            this.sourceColor.push("#c4302b")
          } else if (e.source == "twitter") {
            this.sourceColor.push("#00acee")
          } else if (e.source == "tiktok") {
            this.sourceColor.push("#000000")
          } else {
            this.sourceColor.push("#777777")
          }
        })
      })
    },
    generateChart() {
      this.isReady = false
      this.series = this.sourceNumber
      this.options = {
        chart: {
          width: 460,
          type: "pie",
        },
        labels: this.sourceName,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 380,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors: this.sourceColor,
      }

      this.isReady = true
    },
  },
}
</script>
