<template>
  <div>
    <!--begin::Dashboard-->
    <ActiveList></ActiveList>
    <b-row>
      <b-col md="6">
        <ActiveSourcesChart></ActiveSourcesChart>
      </b-col>
      <b-col md="6">
        <IncidentAlertVolume></IncidentAlertVolume>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col md="6">
        <IncidentAlertByTimePeriod></IncidentAlertByTimePeriod>
      </b-col>
      <b-col md="6">
        <IncidentOccurChart></IncidentOccurChart>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col><IncidenthourlyUpdateChart></IncidenthourlyUpdateChart></b-col>
    </b-row>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ActiveList from "@/view/content/widgets/stats/activeList.vue";
import ActiveSourcesChart from "@/view/content/widgets/stats/activeSources.vue";
import IncidentOccurChart from "@/view/content/widgets/stats/incidentOccur.vue";
import IncidenthourlyUpdateChart from "@/view/content/widgets/stats/hourlyUpdate.vue";
import IncidentAlertByTimePeriod from "./IncidentAlertByTimePeriod";
import IncidentAlertVolume from "./IncidentAlertVolume.vue";

export default {
  name: "dashboard",
  components: {
    ActiveList,
    ActiveSourcesChart,
    IncidentOccurChart,
    IncidentAlertByTimePeriod,
    IncidentAlertVolume,
    IncidenthourlyUpdateChart
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {}
};
</script>
