<template>
  <KTCard class="h-100 no-gutter">
    <template v-slot:title>
      <h6 class="mb-0">Incident Alert Volume</h6>
    </template>
    <template v-slot:body>
      <div class="text-right">
        <el-date-picker
          v-model="range"
          type="monthrange"
          align="left"
          size="mini"
          start-placeholder="Start Year"
          end-placeholder="End Year"
          value-format="timestamp"
          format="MM/yyyy"
        >
        </el-date-picker>
      </div>

      <apexchart :options="options" :series="series" v-if="isReady"></apexchart>
    </template>
  </KTCard>
</template>

<script>
import KTCard from "@/view/content/Card"
import { DashboardService, CustomerService } from "@/core/services"
import moment from "moment"
import { mapGetters } from "vuex"
import _ from "lodash"

export default {
  name: "IncidentAlertVolume",
  components: { KTCard },
  data() {
    return {
      range: [
        moment()
          .startOf("year")
          .valueOf(),
        moment().valueOf(),
      ],
      options: {
        chart: {
          type: "line",
          id: "incident-alert-by-time-period",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          show: true,
          width: 2,
          curve: "straight",
        },
        xaxis: {
          type: "datetime",
          categories: [],
          // labels: {
          //   formatter: (val) => {
          //     return moment(val)
          //       .add(7, "hour")
          //       .format("MMM")
          //   },
          // },
        },
        yaxis: {
          title: {
            text: "Alert (times)",
          },
          labels: {
            formatter: (val) => {
              return this.$options.filters.number_format(val)
            },
          },
        },
      },
      series: [
        // {
        //   name: "series-1",
        //   data: [30, 40, 45, 50, 49, 60, 70, 91],
        // },
      ],
      isReady: false,
    }
  },
  mounted() {
    this.getBrandsColors().then(() => {
      this.init()
    })
  },
  watch: {
    range() {
      this.init()
    },
  },
  methods: {
    async getBrandsColors() {
      let response = await CustomerService.get({ limit: 0 })
      let colors = {}
      for (let result of response.results) {
        colors[result.cusId] = result.brandColor
      }
      return colors
    },
    async init() {
      let result = await DashboardService.getIncidentAlertMonthly({
        since: this.range[0],
        until: moment(this.range[1])
          .endOf("month")
          .valueOf(),
      })

      this.series = result.brands.map((v) => {
        let datas = []
        for (let month of result.monthly) {
          let monthlyData = v.data.filter((v) => v.date === month)[0]
          datas.push({
            x: moment(month).valueOf(),
            y: monthlyData ? monthlyData.count : 0,
          })
        }

        return {
          cusId: v.cusId,
          name: v.brand_name,
          data: datas,
        }
      })
      this.options.xaxis.labels = {
        style: {
          colors: this.layoutConfig("colors.gray.gray-500"),
          fontSize: "10px",
          fontFamily: this.layoutConfig("font-family"),
        },
      }

      let brandColors = await this.getBrandsColors()
      this.options.colors = this.series.map((v) => brandColors[v.cusId])
      this.options = _.cloneDeep(this.options)

      this.isReady = true
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
}
</script>

<style></style>
