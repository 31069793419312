<template>
  <KTCard class="h-auto">
    <template v-slot:title>
      <h6 class="mb-0">Topic Created</h6>
    </template>
    <template v-slot:body>
      <div class="text-right">
        <el-date-picker
          v-model="inputTimeRange"
          type="daterange"
          align="left"
          size="mini"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          value-format="timestamp"
          format="dd/MM/yyyy"
        >
        </el-date-picker>
      </div>

      <apexchart v-if="isReady" :options="options" :series="series"></apexchart>
    </template>
  </KTCard>
</template>

<style lang="scss">
.el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 290px;
}
</style>

<script>
import { DashboardService } from "@/core/services";
import { mapGetters } from "vuex";
import moment from "moment";
import KTCard from "@/view/content/Card";
// import _ from "lodash";
export default {
  name: "hourlyUpdate",
  components: {
    KTCard
  },
  props: {},
  data() {
    return {
      timeRange: [],
      incidentNum: [],
      inputTimeRange: [
        moment()
          .startOf("years")
          .valueOf(),
        moment().valueOf()
      ],

      options: {},
      series: [],
      isReady: false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.getTopicCreated().then(() => {
      this.generateChart();
    });
  },
  watch: {
    inputTimeRange() {
      this.getTopicCreated().then(() => {
        this.generateChart();
      });
    }
  },
  methods: {
    async getTopicCreated() {
      this.timeRange = [];
      this.incidentNum = [];
      let response = await DashboardService.getTopicCreated({
        since: this.inputTimeRange[0],
        until: moment(this.inputTimeRange[1])
          .endOf("days")
          .valueOf()
      });
      this.series = response;
    },

    generateChart() {
      this.isReady = false;
      // this.series = [
      //   {
      //     name: "Monday",
      //     data: this.generateData({ min: 0, max: 30 })
      //   },
      //   {
      //     name: "Tuesday",
      //     data: this.generateData({ min: 0, max: 30 })
      //   },
      //   {
      //     name: "Wed",
      //     data: this.generateData({ min: 0, max: 30 })
      //   },
      //   {
      //     name: "Thurs",
      //     data: this.generateData({ min: 0, max: 30 })
      //   },
      //   {
      //     name: "Friday",
      //     data: this.generateData({ min: 0, max: 30 })
      //   },
      //   {
      //     name: "Sat",
      //     data: this.generateData({ min: 0, max: 30 })
      //   },
      //   {
      //     name: "Sun",
      //     data: this.generateData({ min: 0, max: 30 })
      //   }
      // ];
      this.options = {
        series: this.series,

        chart: {
          height: 350,
          type: "heatmap"
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#fd8007"],
        // title: {
        //   text: "HeatMap Chart (Single color)"
        // },
        xaxis: {
          labels: {
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "10px",
              fontFamily: this.layoutConfig("font-family")
            }
          }
        }
      };
      // this.options = _.cloneDeep({
      //   chart: {
      //     type: "bar",
      //     id: "incident-occur",
      //     toolbar: {
      //       show: false
      //     },
      //   },
      //   plotOptions: {
      //     bar: {
      //       dataLabels: {
      //         position: "top" // top, center, bottom
      //       }
      //     }
      //   },
      //   dataLabels: {
      //     enabled: true,
      //     textAnchor: "start",
      //     formatter: val => {
      //       return this.$options.filters.number_format(val);
      //     },
      //     style: {
      //       fontSize: "9px",
      //       colors: ["#000"]
      //     },
      //     offsetY: -20,
      //     offsetX: -5
      //   },
      //   xaxis: {
      //     categories: this.timeRange,
      //     labels: {
      //       style: {
      //         colors: this.layoutConfig("colors.gray.gray-500"),
      //         fontSize: "10px",
      //         fontFamily: this.layoutConfig("font-family")
      //       },
      //       hideOverlappingLabels: true,
      //       rotate: -90,
      //       datetimeFormatter: {
      //         year: "yyyy",
      //         month: "MMM 'yy",
      //         day: "dd MMM",
      //         hour: "HH:mm"
      //       }
      //     }
      //   },
      //   yaxis: {
      //     show: true,
      //     labels: {
      //       show: true,
      //       formatter: val => {
      //         return this.$options.filters.number_format(val);
      //       }
      //     },
      //     title: {
      //       text: "Alert (times)"
      //     }
      //   },
      //   colors: ["#fd8007"]
      // });

      this.isReady = true;
    },
    generateData(yrange) {
      var i = 0;
      var series = [];
      while (i < 24) {
        var x = `${i}`.padStart(2, "0");
        var y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
          yrange.min;

        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    }
  }
};
</script>
