<template>
  <KTCard class="h-100">
    <template v-slot:title>
      <h6 class="mb-0">Incident Occur</h6>
    </template>
    <template v-slot:body>
      <div class="text-right">
        <el-date-picker
          v-model="inputTimeRange"
          type="daterange"
          align="left"
          size="mini"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          value-format="timestamp"
          format="dd/MM/yyyy"
        >
        </el-date-picker>
      </div>

      <apexchart v-if="isReady" :options="options" :series="series"></apexchart>
    </template>
  </KTCard>
</template>

<style lang="scss">
.el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 290px;
}
</style>

<script>
import { DashboardService } from "@/core/services";
import { mapGetters } from "vuex";
import moment from "moment";
import KTCard from "@/view/content/Card";
// import _ from "lodash";
export default {
  name: "incidentOccur",
  components: {
    KTCard
  },
  props: {},
  data() {
    return {
      timeRange: [],
      incidentNum: [],
      inputTimeRange: [
        moment()
          .startOf("week")
          .startOf("days")
          .valueOf(),
        moment().valueOf()
      ],

      options: {},
      series: [],
      isReady: false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.getIncidentOccur().then(() => {
      this.generateChart();
    });
  },
  watch: {
    inputTimeRange() {
      this.getIncidentOccur().then(() => {
        this.generateChart();
      });
    }
  },
  methods: {
    async getIncidentOccur() {
      this.timeRange = [];
      this.incidentNum = [];
      let response = await DashboardService.getIncidentOccur({
        since: this.inputTimeRange[0],
        until: moment(this.inputTimeRange[1])
          .endOf("days")
          .valueOf()
      });

      // this.timeRange = response.map(d =>
      //   moment(d.hourly).format("DD/MM/YYYY HH:mm")
      // );
      // this.incidentNum = response.map(d => d.count);

      this.incidentNum = response.map(d => {
        return [d.hourly, d.count];
      });
    },

    generateChart() {
      this.isReady = false;
      this.series = [
        {
          name: "Incident",
          data: this.incidentNum
        }
      ];
      this.options = {
        series: this.series,
        chart: {
          type: "area",
          stacked: false,
          height: 200,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: "zoom"
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          }
        },
        yaxis: {
          // labels: {
          //   formatter: function(val) {
          //     return val.toFixed(0);
          //   }
          // },
          title: {
            text: "Alert(times)"
          }
        },
        colors: ["#fd8007"],
        xaxis: {
          type: "datetime",
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "10px",
            fontFamily: this.layoutConfig("font-family")
          },
          labels: {
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "10px",
              fontFamily: this.layoutConfig("font-family")
            }
          }
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function(val) {
              return val.toFixed(0);
            }
          },
          x: {
            format: "dd MMM HH:mm"
          }
        }
      };

      this.isReady = true;
    }
  }
};
</script>
